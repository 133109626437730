import {mapState} from "vuex";
import {VUEX_BUYOUT} from "js-mp/src/vue/store/buyout";

export const validationRules = {
	data() {
		return {
			rules: {
				required: value => !!value || this.$t('validators.required'),
				length: length => value =>
					!value ||
					(value && value.length === length) ||
					this.$t('Must have {length} characters', { length }),
				max: maxlength => value =>
					!value ||
					(value && value.length <= maxlength) ||
					this.$t('Max {maxlength} characters', { maxlength }),
				min: minlength => value =>
					!value ||
					(value && value.length >= minlength) ||
					this.$t('Min {minlength} characters', { minlength }),
				maxValue: maxValue => value =>
					value <= maxValue ||
					this.$t('Max allowed value is {maxValue}.', { maxValue }),
				minValue: minValue => value =>
					value >= minValue ||
					this.$t('validators.min_allowed_value', { minValue }),
				maxValueFloat: maxValue => value =>
					this.convertValue(value) <= maxValue ||
					this.$t('Max allowed value is {maxValue}.', { maxValue }),
				minValueFloat: minValue => value =>
					this.convertValue(value) >= minValue ||
					this.$t('Min allowed value is {minValue}.', { minValue }),
				// isNumber: value =>
				// 	!value ||
				// 	(value && /^\d*$/.test(value)) ||
				// 	this.$t('Value has to be number.'),
				dateIsValid: value =>
					this.validateDate(value) || this.$t('validators.is_date_valid'),
				dateIsNewer: value =>
					this.convertDate(value) || this.$t('validators.is_newer_date_valid'),
				isNumber: value =>
					!value ||
					(value.match(/^[0-9]+$/) != null) ||
					this.$t('validators.is_number'),
				imei: value =>
					!value || this.isIMEIValid(value) || this.$t('validators.invalid_imei'),
				isBankNumberValid: value =>
					!value ||
					this.isBankNumberValid(value) ||
					this.$t('validators.invalid_bank_account_number'),
				isBankCodeValid: value =>
					!value ||
					this.isBankCodeValid(value) ||
					this.$t('validators.invalid_bank_code'),
				valueIsNotOnlyNumbers: value =>
					!value ||
					(value && !/^\d*$/.test(value)) ||
					this.$t("Your password can't be entirely numeric."),
				postCode: value =>
					!value ||
					(value && /^\d{5}$|^\d{3} \d{2}$/.test(value)) ||
					this.$t('validators.valid_post_code'),
				email: value => {
					const pattern =
						/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
					return pattern.test(value) || this.$t('validators.invalid_email');
				},
				isPinValid: value =>
					!value ||
					((this.validatePIN(value) && ['CZE'].includes(this.contractForm.customer_data.nationality)) || (this.validateDate(value) && this.contractForm.customer_data.nationality !== 'CZE')) ||
					this.$t('validators.is_pin_valid'),
			}
		}
	},
  computed: {
    ...mapState(VUEX_BUYOUT.NAMESPACE, [
      'contractForm',
    ]),
  },
	methods: {
		validateDate(date) {
			if (!date) return false;
			const re = /^([1-9]|0[1-9]|[12][0-9]|3[01])[.]([1-9]|0[1-9]|1[012])[.](19|20)\d\d$/;
			return re.test(date);
		},
		convertDate(value) {
			let [day, month, year] = [null, null, null];
			if (value) [day, month, year] = value.split('.');
			if (day && month && year) {
				return new Date(year, month, day).getTime() >= Date.now();
			}
			return true;
		},
		convertValue(v) {
			if (typeof v === 'string') {
				return parseFloat(v.replace(/,/g, '.')); // convert input with coma to dot e.g. 5,5 => 5.5
			}
			return v;
		},
		isBankNumberValid(number) {
			const match = number.match(/^((\d{0,6})-)?(\d{1,10})$/);
			if (!match) return false;

			const rawPrefix = match[2] || '';
			const rawBankNumber = match[3] || '';
			const prefix = rawPrefix.padStart(6, '0');
			const bankNumber = rawBankNumber.padStart(10, '0');

			const isOkFirst =
				(10 * prefix[0] +
					5 * prefix[1] +
					8 * prefix[2] +
					4 * prefix[3] +
					2 * prefix[4] +
					1 * prefix[5]) %
					11 ===
				0;
			if (!isOkFirst) {
				return false;
			}
			return (
				(6 * bankNumber[0] +
					3 * bankNumber[1] +
					7 * bankNumber[2] +
					9 * bankNumber[3] +
					10 * bankNumber[4] +
					5 * bankNumber[5] +
					8 * bankNumber[6] +
					4 * bankNumber[7] +
					2 * bankNumber[8] +
					1 * bankNumber[9]) %
					11 ===
				0
			);
		},
		isBankCodeValid(code) {
			const CODES = [
				'0100',
				'0300',
				'0600',
				'0710',
				'0800',
				'2010',
				'2020',
				'2030',
				'2060',
				'2070',
				'2100',
				'2200',
				'2220',
				'2240',
				'2250',
				'2260',
				'2310',
				'2600',
				'2700',
				'3030',
				'3050',
				'3060',
				'3500',
				'4000',
				'4300',
				'5500',
				'5800',
				'6000',
				'6100',
				'6200',
				'6210',
				'6300',
        '6363',
				'6700',
				'6800',
				'7910',
				'7940',
				'7950',
				'7960',
				'7970',
				'7980',
				'7990',
				'8030',
				'8040',
				'8060',
				'8090',
				'8150',
				'8200',
				'8220',
				'8230',
				'8240',
				'8250'
			];
			return CODES.includes(code);
		},
		isIMEIValid(code) {
			const len = code.length;
			const parity = len % 2;
			let sum = 0;
			for (let i = len - 1; i >= 0; i--) {
				let d = parseInt(code.charAt(i));
				if (i % 2 === parity) {
					d *= 2;
				}
				if (d > 9) {
					d -= 9;
				}
				sum += d;
			}
			return sum % 10 === 0;
		},
		compact(number) {
			return number.replace(/\/|\s+/g,'').toUpperCase();
		},
		get_birth_date(number){
			let year = 1900 + parseInt(number.substring(0, 2))
			let month = parseInt(number.substring(2, 4)) % 50 % 20
			let day = parseInt(number.substring(4, 6))

			if (number.length === 9){
				if (year >= 1980) year -= 100
				if (year > 1953) return false
			}
			else if (year < 1954) year += 100
			try {
				return new Date(year, month-1, day)
			}
			catch (e) {
				return false
			}
		},
		validatePIN(number){
			number = this.compact(number)
			if ((number.match(/^[0-9]+$/) == null) || (![9,10].includes(number.length))){
				return false
			}
			let birth_date = this.get_birth_date(number)
			if(birth_date){
				if (number.length === 10){
				let check = parseInt(number.substring(0, number.length - 1)) % 11
				if (birth_date < new Date(1985, 0, 1)) check = check % 10
				if (number.substring(number.length - 1) !== check.toString()) return false
				}
				return true
			}
			return false
		}
	}
}
