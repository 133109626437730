<template>
  <v-dialog
    v-model="dialog"
    content-class="popup deliveryPicker"
    :max-width="confirm ? '730px' : '688px'"
    :scrollable="confirm"
    persistent
  >
    <v-card>
      <v-card-title>
        <div @click="dialog = false">
          <svg class="icon icon--22 cursor--pointer">
            <use href="../assets/svg/symbol-defs.svg#icon-times"/>
          </svg>
        </div>
      </v-card-title>
      <v-card-text>
        <div class="deliveryPicker__content faq">
          <template v-if="!confirm">
            <div class="subtitle semibold color--blue">Jakým způsobem nám zařízení předáte?</div>
            <v-expansion-panels
              :accordion="true"
              :flat="true"
              :hover="true"
              :tile="true"
            >
              <v-expansion-panel
                :ripple="false"
              >
                <v-expansion-panel-header
                  class="semibold"
                  :hide-actions="true"
                >
                  <template v-slot:default="{ open }">
                    Přinesu osobně na podací místo DPD <span class="color--green">zdarma</span>
                    <div :class="[open ? 'is-opened' : 'is-closed']">
                      <svg class="icon fill--blue">
                        <use href="../assets/svg/symbol-defs.svg#icon-arrow"/>
                      </svg>
                    </div>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="faq__message">
                    Na e-mail vám zašleme přepravní štítek. Ten vytisknete a nalepíte na zásilku. Zásilku odevzdáte na
                    jakémkoliv DPD podacím místě.
                    <a href="/dpd" target="_blank">
                      Seznam podacích míst
                      <svg class="icon icon--link fill--blue">
                        <use href="../assets/svg/symbol-defs.svg#icon-link"/>
                      </svg>
                    </a>
                    <v-btn
                      class="mbtn mbtn--green"
                      plain
                      small
                      :depressed="true"
                      :ripple="false"
                      @click="sendDelivery('parcelshop')"
                    >
                      {{ $t('alza.confirm') }}
                    </v-btn>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel
                :ripple="false"
              >
                <v-expansion-panel-header
                  class="semibold"
                  :hide-actions="true"
                >
                  <template v-slot:default="{ open }">
                    Zařízení vyzvedne kurýr na adrese <span class="color--green">zdarma</span>
                    <div :class="[open ? 'is-opened' : 'is-closed']">
                      <svg class="icon fill--blue">
                        <use href="../assets/svg/symbol-defs.svg#icon-arrow"/>
                      </svg>
                    </div>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="faq__message">
                    Kurýr balík zdarma převezme na vámi určeném místě v domluvený čas.
                    <v-form class="app__form">
                      <div class="formItem">
                        <v-text-field
                          name="customer_data_street"
                          class="formInput--street"
                          v-model="address.street"
                          :placeholder="$t('alza.contract_form_data.placeholders.street')"
                          :error-messages="pickupAddressErrors.street"
                          solo
                          flat
                          hide-details="auto"
                        />
                        <div class="app__label">
                          {{ $t('alza.contract_form_data.street_with_number') }}&nbsp; <span class="color--redTone2">*</span>
                        </div>
                      </div>
                      <div class="formItem formItem--gcUnset">
                        <v-text-field
                          name="customer_data_city"
                          v-model="address.city"
                          :placeholder="$t('alza.contract_form_data.placeholders.city')"
                          :error-messages="pickupAddressErrors.city"
                          solo
                          flat
                          hide-details="auto"
                        />
                        <div class="app__label">
                          {{ $t('buyout.contractForm.city') }}&nbsp; <span class="color--redTone2">*</span>
                        </div>
                      </div>
                      <div class="formItem formItem--gcUnset postcode">
                        <v-text-field
                          name="customer_data_postcode"
                          v-model="address.post_code"
                          :placeholder="$t('alza.contract_form_data.placeholders.postcode')"
                          :error-messages="pickupAddressErrors.postcode"
                          solo
                          flat
                          hide-details="auto"
                        />
                        <div class="app__label">
                          {{ $t('buyout.contractForm.postcode') }}&nbsp; <span class="color--redTone2">*</span>
                        </div>
                      </div>
                      <div class="formItem--dates">
                        <v-slide-group
                          show-arrows
                          mandatory
                          v-model="selectedDeliveryDate"
                        >
                          <v-slide-item
                            v-for="(date, index) in dates"
                            :key="index"
                            v-slot="{ active, toggle }"
                          >
                            <v-btn
                              :class="['mbtn', active ? 'mbtn--blue' : '']"
                              plain
                              small
                              rounded
                              :depressed="true"
                              :ripple="false"
                              @click="toggle"
                            >
                              <span class="date date--text">{{ date.text }}&nbsp;</span>
                              <span class="date date--date">{{ date.date }}</span>
                            </v-btn>
                          </v-slide-item>
                        </v-slide-group>
                      </div>
                    </v-form>
                    <v-btn
                      class="mbtn mbtn--green"
                      plain
                      small
                      :depressed="true"
                      :ripple="false"
                      :disabled="isButtonDisabled"
                      @click="sendDelivery('courier')"
                    >
                      {{ $t('alza.confirm') }}
                    </v-btn>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </template>
          <div class="deliveryPicker__personally text-center" v-if="confirmPersonally">
            <svg class="icon icon--check fill--green">
              <use href="../assets/svg/symbol-defs.svg#icon-success"/>
            </svg>
            <div class="subtitle color--green semibold">Hotovo</div>
            <div class="deliveryPicker__grid">
              <div class="gridItem">
                <div class="step color--white semibold">1</div>
                <svg class="icon fill--blue">
                  <use href="../assets/svg/symbol-defs.svg#icon-email"/>
                </svg>
                <div class="desc">
                  Na váš e-mail byl odeslán přepravní štítek.
                </div>
              </div>
              <div class="gridItem">
                <div class="step color--white semibold">2</div>
                <svg class="icon fill--blue">
                  <use href="../assets/svg/symbol-defs.svg#icon-print"/>
                </svg>
                <div class="desc">
                  Štítek vytiskněte a nalepte <span class="noWrap">na zásilku.</span>
                </div>
              </div>
              <div class="gridItem">
                <div class="step color--white semibold">3</div>
                <svg class="icon fill--blue">
                  <use href="../assets/svg/symbol-defs.svg#icon-box"/>
                </svg>
                <div class="desc">
                  Zásilku odevzdejte na jakémkoliv
                  <a href="/dpd" target="_blank">
                    DPD podacím místě
                    <svg class="icon icon--link fill--blue">
                      <use href="../assets/svg/symbol-defs.svg#icon-link"/>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="deliveryPicker__courier text-center" v-if="confirmCourier">
            <svg class="icon icon--check fill--green">
              <use href="../assets/svg/symbol-defs.svg#icon-success"/>
            </svg>
            <div class="subtitle color--green semibold">Přeprava zařízení objednána</div>
            <div class="deliveryPicker__grid">
              <div class="gridItem">
                <div class="step color--white semibold">1</div>
                <svg class="icon fill--blue">
                  <use href="../assets/svg/symbol-defs.svg#icon-email"/>
                </svg>
                <div class="desc">
                  Na váš e-mail byl odeslán přepravní štítek.
                </div>
              </div>
              <div class="gridItem">
                <div class="step color--white semibold">2</div>
                <svg class="icon fill--blue">
                  <use href="../assets/svg/symbol-defs.svg#icon-print"/>
                </svg>
                <div class="desc">
                  Štítek vytiskněte a nalepte <span class="noWrap">na zásilku.</span>
                </div>
              </div>
              <div class="gridItem">
                <div class="step color--white semibold">3</div>
                <svg class="icon fill--blue">
                  <use href="../assets/svg/symbol-defs.svg#icon-transport"/>
                </svg>
                <div class="desc">
                  Počkejte na SMS od kurýra s přesným termínem svozu.
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card-text>
      <v-card-actions v-if="confirm">
        <v-btn
          class="mbtn mbtn--gray"
          plain
          small
          :depressed="true"
          :ripple="false"
          @click="dialog = false"
        >
          {{ $t('alza.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as moment from "moment";
import "moment/dist/locale/cs";
import {apiAxios} from "js-mp/src/js/buyout";
import {URLS as API_URLS} from "../store/alzaBuyout";
import {eventBus} from "@/main";
import {validationRules} from '@/mixins/validation';
import {GETTER_TYPES} from "js-mp/src/vue/store/buyout/_getter-types";
import {ACTION_TYPES} from "js-mp/src/vue/store/buyout/_action-types";
import {mapActions, mapGetters, mapState} from "vuex";
import {VUEX_BUYOUT} from "js-mp/src/vue/store/buyout";
import {CzechHolidays} from "czech-holidays";

export default {
  name: "DeliveryPicker",
  mixins: [validationRules],
  data() {
    return {
      dialog: true,
      confirm: false,
      confirmPersonally: false,
      confirmCourier: false,
      selectedDeliveryDate: null,
      pickupAddressErrors: {
        city: '',
        street: '',
        post_code: '',
        country_identifier: ''
      },
      address: {
        city: '',
        street: '',
        post_code: '',
        country_identifier: ''
      },
      dates: []
    }
  },
  created() {
    if (!this[GETTER_TYPES.STATES].length) this[ACTION_TYPES.LOAD_STATES]();
    this.getDates();
  },
  computed:{
    ...mapState(VUEX_BUYOUT.NAMESPACE, [
      'userBuyoutCode',
    ]),
    ...mapGetters(VUEX_BUYOUT.NAMESPACE, [
      GETTER_TYPES.STATES,
    ]),
    isButtonDisabled(){
      return !(this.address.city && this.address.street && this.address.post_code && typeof this.selectedDeliveryDate === 'number')
    }
  },
  methods: {
    ...mapActions(VUEX_BUYOUT.NAMESPACE, [
      ACTION_TYPES.LOAD_STATES,
    ]),
    getDates(){
      moment.locale("cs");
      const noOfSlots = 3;
      const now = moment()
      const slots=[];
      now.add(1, "days");
      const holidays = CzechHolidays(now.year());
      const isDeadlineOffset = !!(now.hours() >= 20 && now.minutes() > 0);
      const isWeekend = (day) => {
        return (day === 6) || (day === 0);
      }
      const isPublicHoliday = (date) => {
        return holidays.some(e => e.m === (date.month() + 1) && e.d === date.date());
      }
      const isValidDate = (date) => {
        return !(isWeekend(date.day()) || isPublicHoliday(date));
      }
      if (isDeadlineOffset) { now.add(1, "days"); }
      do {
        if (isValidDate(now)) {
          slots.push({"date": now.format("DD.MM."), "text": now.calendar(null, {
            sameDay: "[dnes]",
            nextDay: "[zítra]",
            nextWeek: "dddd",
            lastDay: "[včera]",
            lastWeek: "[minulý] dddd",
            sameElse: "DD.MM.YYYY"
          })});
        }
        now.add(1, "days");
      } while (!isValidDate(now) || slots.length < noOfSlots);
      this.dates = slots;
    },
    formatDate(date) {
      if (moment(date, 'DD.MM.YYYY').isValid()) {
        let selectedDate = moment(date, 'DD.MM.YYYY');
        if (selectedDate.isBefore(moment())) {   // selected date is in the next year - we must add a year
          selectedDate.add(1, 'year');
        }
        return selectedDate.format('YYYY-MM-DD');
      }
      return date;
    },
    async sendDelivery(type){
      try {
        this.pickupAddressErrors={
          city: '',
          street: '',
          post_code: '',
        }
        await apiAxios.post(
          `${API_URLS.BUYOUT}/${
            API_URLS.PICKUP_ADDRESS
          }/${this.userBuyoutCode}`,
          {
            street: type === 'courier' ? this.address.street : null,
            city: type === 'courier' ? this.address.city : null,
            postcode: type === 'courier' ? this.address.post_code : null,
            country_identifier: 'CZE',
            delivery_date: type === 'courier' ? this.formatDate(this.dates[this.selectedDeliveryDate].date) : null,
            delivery_type: type
          }
        );
        this.confirm = true;
        if(type==='courier') this.confirmCourier = true;
        else this.confirmPersonally = true
      } catch (err) {
        if (err.response && err.response.status === 400) {
          this.pickupAddressErrors=err.response.data
        } else if (err.response && err.response.status === 409) {
          eventBus.$emit("showErrorMessage", {message: err.response.data.error.join(", "), is_error: true});
        } else {
          eventBus.$emit('showErrorMessage', {message: 'Chyba při zpracovávání požadavku', is_error: true});
        }
      }
    }
  }
}
</script>
