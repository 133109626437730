import { allowed_date_formats } from './config';

export const overrides = {
  cs: {
    buyout: {
      need_visit_shop: 'Pro uskutečnění výkupu online je třeba mít vytvořenou objednávku na nové zařízení v našem eshopu',
      need_visit_shop_tutorial: 'kompletní návod tady',
      search_for_device: 'Vyberte zařízení k výkupu',
      contractForm: {
        note: 'Číslo objednávky nového zařízení',
        imei_help_text: 'IMEI kód zjistíte stisknutím kláves *#06# na vašem telefonu.',
        sn_help_text: 'SN kód zjistíte v nastavení v sekci o zařízení.',
        pin_or_birth_date: 'Rodné číslo / Datum narození (pouze cizinci)',
      },
      sell_online: 'Výkup zařízení online',
      send_info_email: 'Výkup zařízení na prodejně',
      thankYou: {
        onlineBuyout: {
          title: 'Informativní nacenění zařízení bylo odesláno na Vaši e-mailovou adresu pod číslem <b>{0}</b>.',
        }
      },
      deviceFinder: {
        chose_type: 'Typ zařízení',
        chose_brand: 'Značka',
        chose_model: 'Model'
      },
      BuyoutState: {
        collection_success: 'Přeprava úspěšně objednána',
        collection_already_ordered: 'Přeprava zařízení již byla objednána',
        something_went_wrong: 'Něco se pokazilo',
        collection_success_description: 'O přesném termínu vás budeme dopředu informovat e-mailem.',
        collection_already_ordered_description: 'O přesném termínu vás budeme dopředu informovat e-mailem.',
        something_went_wrong_description: 'Pro další informace nám <a href="/#contact_us" class="underline">napište</a>, nebo zavolejte na <a href="tel:225340111" class="underline">225 340 111</a>',
      },
      Revaluation:{
        revaluation_success: 'Nová cena zařízení přijata',
        revaluation_denied: 'Nová cena zařízení odmítnuta',
        revaluation_closed: 'Výkup již byl uzavřen',
        revaluation_success_description: 'E-mailem vám zašleme dodatek kupní smlouvy. Ten nám prosím <b>podepište, naskenujte a zašlete zpět</b>. Jakmile dodatek obdržíme, bude vám výkupní cena připsána na bankovní účet.',
        revaluation_denied_description: 'Vaše zařízení vám zašleme zdarma zpět na vaši adresu. O přesném termínu vás budeme dopředu informovat e-mailem.',
        revaluation_closed_description: '',
      },
    },
    alza: {
      buyout_finished: 'Výkup zařízení úspěšně dokončen',
      buyout_email_sent: 'Do e-mailu jsme vám poslali další postup a rekapitulaci.',
      buyout_thank_you: 'Děkujeme, že jste využili naše služby.',
      buyout_another_device: 'Vykoupit další zařízení',
      go_back_to_alza: 'Zpět k nákupu',
      main_title_first: 'Prodejte staré',
      main_title_second: 'bude na nové',
      faq: 'Často kladené otázky',
      find_device: 'Výběr zařízení',
      device_appraisal: 'Výpočet výkupní ceny',
      contract_form: 'Uzavření smlouvy',
      done: 'Hotovo',
      buyout: 'Zjistěte výkupní cenu vašeho zařízení',
      buyout_subtitle: 'Uvedené ceny výkupu jsou orientační. Finální částku se dozvíte až po kontrole zařízení našim technikem.',
      continue: 'Pokračovat',
      go_back: 'Zpět',
      understand: 'Rozumím',
      close: 'Zavřít',
      confirm: 'Potvrdit volbu',
      choose: 'Vyberte...',
      deviceFinder: {
        chose_color: 'Barva',
      },
      device_state: 'V jakém stavu se nachází vaše zařízení?',
      chose_state: 'Stav zařízení',
      change: 'Změnit',
      buyout_price: 'Výkupní cena',
      buyout_cannot_finish: 'Výkup nelze dokončit',
      quality_error: 'Zařízení nemá požadovanou kvalitu',
      appraisal_detail: 'Detailní popis',
      finish_buyout: 'Dokončit výkup',
      contact_form: {
        do_you_have_questions: 'Máte dotaz?',
        write_us: 'Napište nám',
        e_mail: 'E-mail',
        full_name: 'Jméno a příjmení',
        your_question: 'Váš dotaz',
        send_question: 'Odeslat dotaz'
      },
      contract_form_data: {
        name_error: 'Prosím vyplňte jméno i přijmení',
        contract_info: 'Vyplňte prosím následující údaje nutné k uzavření kupní smlouvy',
        device_for_buyout: 'Vykupované zařízení',
        identity_card: 'Občanský průkaz nebo pas',
        identity_card_number: 'Číslo dokladu',
        new_device_order_number: 'Číslo objednávky nového zařízení',
        new_device_device_type: 'Typ nového zařízení',
        new_device_imei: 'IMEI nového zařízení',
        permanent_residence: 'Adresa trvalého bydliště',
        street_with_number: 'Ulice a číslo popisné',
        different_pickup_place: 'Chci, abyste pro vykupované zařízení přijeli na jinou adresu',
        phone_number: 'Telefonní číslo',
        contact_info: 'Kontaktní informace',
        bank_connection: 'Bankovní spojení',
        bank_account_and_code: 'Číslo bankovního účtu vč. předčíslí (kam chcete poslat peníze)',
        order_number_tooltip: 'Číslo objednávky naleznete v sekci <a href="https://www.alza.cz/muj-ucet/objednavky.htm" class="underline" target="_blank">Moje Alza</a> nebo v e-mailu.',
        placeholders:{
          imei: '123456789012345',
          new_order_number: '123 456 789',
          select_new_device_type: 'Vyberte typ nového zařízení',
          degree: 'Ing.',
          full_name: 'Jan Novák',
          first_name: 'Jan',
          last_name: 'Novák',
          place_of_birth: 'Pardubice',
          personal_identification_number: 'Rodné číslo nebo DD.MM.RRRR',
          nationality: 'Vyberte stát',
          identity_card_number: '123456789',
          identity_released_by: 'ÚMČ Praha 1',
          identity_valid_to: 'DD.MM.RRRR',
          street: 'Luxemburská 1234/56',
          city: 'Praha 1',
          postcode: '110 00',
          email: '@',
          phone_number: '123 456 789',
          bank_account: '35-123456789',
          bank_code: '1234',
        },
      }
    },
    validators: {
			required: 'Toto pole je povinné.',
			is_number: 'Toto pole může obsahovat pouze číslice.',
			invalid_bank_account_number: 'Neplatné číslo účtu.',
			invalid_bank_code: 'Neplatný kód banky.',
			min_allowed_value: 'Nejnižší povolená hodnota je: {minValue}.',
			invalid_email: 'Neplatný email',
			valid_post_code: 'Hodnota musí být platné PSČ',
			is_newer_date_valid: 'Datum musí být pozdější než dnešní',
			is_date_valid:
				'Chybný formát data. Použijte prosím jeden z těchto formátů: ' +
				allowed_date_formats.join(', '),
      is_pin_valid: 'Neplatné rodné číslo nebo datum narození (pouze cizinci)',
      invalid_imei: 'Neplatný formát IMEI. Zadejte pouze čísla. IMEI zjistíte zadáním *#06#'
		}
  }
}
